var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "FormulateForm",
        { ref: "form", attrs: { name: "formStore" } },
        [
          _c(
            "b-card-actions",
            {
              ref: "main-card",
              attrs: {
                "no-actions": "",
                "show-loading": _vm.loading || _vm.busy,
                title: _vm.$t("Dados Cadastrais"),
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _vm.isEdition
                        ? _c("FormulateInput", {
                            attrs: {
                              id: "store-id",
                              name: "id",
                              label: _vm.$t("Id"),
                              type: "label",
                            },
                            model: {
                              value: _vm.store.id,
                              callback: function ($$v) {
                                _vm.$set(_vm.store, "id", $$v)
                              },
                              expression: "store.id",
                            },
                          })
                        : _c("FormulateInput", {
                            staticClass: "required",
                            attrs: {
                              id: "store-id",
                              name: "id",
                              label: _vm.$t("Id"),
                              type: "number",
                              validation: "required",
                            },
                            model: {
                              value: _vm.store.id,
                              callback: function ($$v) {
                                _vm.$set(_vm.store, "id", $$v)
                              },
                              expression: "store.id",
                            },
                          }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "company-name",
                          name: "companyName",
                          label: _vm.$t("Razão Social"),
                          type: "text",
                          validation: "required",
                        },
                        model: {
                          value: _vm.store.companyName,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "companyName", $$v)
                          },
                          expression: "store.companyName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "trading-name",
                          name: "tradingName",
                          type: "text",
                          label: _vm.$t("Nome Fantasia"),
                          validation: "required",
                        },
                        model: {
                          value: _vm.store.tradingName,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "tradingName", $$v)
                          },
                          expression: "store.tradingName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-store-combo", {
                        attrs: {
                          id: "order-store_id",
                          name: "headquartersStoreId",
                          required: false,
                          label: _vm.$t("Matriz"),
                        },
                        model: {
                          value: _vm.store.headquartersStoreId,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "headquartersStoreId", $$v)
                          },
                          expression: "store.headquartersStoreId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "type",
                          name: "type",
                          type:
                            _vm.isEdition && _vm.store.type === "Kiosk"
                              ? "label"
                              : "vue-select",
                          validation: "required",
                          label: _vm.$t("Tipo"),
                          options: _vm.storeTypes(),
                          placeholder: _vm.$t("Selecione"),
                        },
                        model: {
                          value: _vm.store.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "type", $$v)
                          },
                          expression: "store.type",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "region",
                          name: "region",
                          type: "label",
                          label: _vm.$t("Região"),
                        },
                        model: {
                          value: _vm.store.region,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "region", $$v)
                          },
                          expression: "store.region",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "businessModel",
                          name: "businessModel",
                          type: "vue-select",
                          validation: "required",
                          label: _vm.$t("Modelo"),
                          options: _vm.storeBusinessModels(),
                          placeholder: _vm.$t("Selecione"),
                        },
                        model: {
                          value: _vm.store.businessModel,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "businessModel", $$v)
                          },
                          expression: "store.businessModel",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "headId",
                          name: "headId",
                          type: "vue-select",
                          validation: "required",
                          label: _vm.$t("Head"),
                          options: _vm.headOptions,
                          placeholder: _vm.$t("Selecione"),
                        },
                        model: {
                          value: _vm.store.headId,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "headId", $$v)
                          },
                          expression: "store.headId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "directprId",
                          name: "directorId",
                          type: "vue-select",
                          label: _vm.$t("Diretor"),
                          options: _vm.directorOptions,
                          placeholder: _vm.$t("Selecione"),
                        },
                        model: {
                          value: _vm.store.directorId,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "directorId", $$v)
                          },
                          expression: "store.directorId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "cnpj",
                          name: "cnpj",
                          type: "text-mask",
                          mask: ["##.###.###/####-##"],
                          validation: "required|cnpj",
                          label: _vm.$t("CNPJ"),
                        },
                        model: {
                          value: _vm.store.cnpj,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "cnpj", $$v)
                          },
                          expression: "store.cnpj",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "state-registration",
                          name: "stateRegistration",
                          type: "text",
                          validation: "required",
                          maxlength: "20",
                          label: _vm.$t("Inscrição Estadual"),
                        },
                        model: {
                          value: _vm.store.stateRegistration,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "stateRegistration", $$v)
                          },
                          expression: "store.stateRegistration",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "state-registration-of-tax-substitute",
                          name: "stateRegistrationOfTaxSubstitute",
                          type: "text",
                          maxlength: "20",
                          label: _vm.$t("I.E. do Substituto Tributário"),
                          instruction: _vm.$t(
                            "Inscrição Estadual do Substituto Tributário"
                          ),
                        },
                        model: {
                          value: _vm.store.stateRegistrationOfTaxSubstitute,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.store,
                              "stateRegistrationOfTaxSubstitute",
                              $$v
                            )
                          },
                          expression: "store.stateRegistrationOfTaxSubstitute",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "municipal-inscription",
                          name: "municipalInscription",
                          type: "text",
                          maxlength: "20",
                          label: _vm.$t("Inscrição Municipal"),
                        },
                        model: {
                          value: _vm.store.municipalInscription,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "municipalInscription", $$v)
                          },
                          expression: "store.municipalInscription",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "investors",
                          name: "investors",
                          type: "vue-select",
                          multiple: "",
                          label: _vm.$t("Investidores"),
                          options: _vm.investorsOptions,
                          placeholder: _vm.$t("Selecione"),
                        },
                        model: {
                          value: _vm.store.investors,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "investors", $$v)
                          },
                          expression: "store.investors",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "email",
                          name: "email",
                          type: "text",
                          label: _vm.$t("E-mail"),
                          validation: "required",
                        },
                        model: {
                          value: _vm.store.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "email", $$v)
                          },
                          expression: "store.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "1" } },
                    [
                      _c("p", { staticClass: "h6" }, [_vm._v(" Ativo? ")]),
                      _c("e-status-badge", {
                        attrs: { status: !!_vm.store.active, type: "yesNo" },
                      }),
                    ],
                    1
                  ),
                  _vm.store.type === "Kiosk"
                    ? _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("FormulateInput", {
                            staticClass: "required",
                            attrs: {
                              id: "beerCoolerIntegrationStoreId",
                              name: "beerCoolerIntegrationStoreId",
                              type: "number",
                              label: _vm.$t("Id integração Chopeira"),
                              validation: "required",
                              instruction: _vm.$t(
                                "Id da loja no sistema de integração com a chopeira"
                              ),
                            },
                            model: {
                              value: _vm.store.beerCoolerIntegrationStoreId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.store,
                                  "beerCoolerIntegrationStoreId",
                                  $$v
                                )
                              },
                              expression: "store.beerCoolerIntegrationStoreId",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-card-actions",
            {
              attrs: {
                "no-actions": "",
                "show-loading": _vm.loading || _vm.busy,
                title: _vm.$t("Delivery"),
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "store-delivery",
                          name: "delivery",
                          type: "switch",
                          label: _vm.$t("Oferece Delivery?"),
                        },
                        model: {
                          value: _vm.store.delivery,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "delivery", $$v)
                          },
                          expression: "store.delivery",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        class: _vm.store.delivery ? "required" : "",
                        attrs: {
                          id: "email-delivery",
                          name: "emailDelivery",
                          type: "text",
                          label: _vm.$t("E-mail Delivery"),
                          instruction: _vm.$t(
                            "E-mail usado para o processo de entregas"
                          ),
                          validation: _vm.store.delivery ? "required" : "",
                        },
                        model: {
                          value: _vm.store.emailDelivery,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "emailDelivery", $$v)
                          },
                          expression: "store.emailDelivery",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        class: _vm.store.delivery ? "required" : "",
                        attrs: {
                          id: "max-schedule",
                          name: "maxScheduleDays",
                          type: "text-number",
                          label: _vm.$t("Qtd. dias para entrega"),
                          instruction: _vm.$t(
                            "Quantidade máxima de dias para entrega de pedidos."
                          ),
                          validation: _vm.store.delivery ? "required" : "",
                        },
                        model: {
                          value: _vm.store.maxScheduleDays,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "maxScheduleDays", $$v)
                          },
                          expression: "store.maxScheduleDays",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        class: _vm.store.delivery ? "required" : "",
                        attrs: {
                          id: "min-delivery-schedule-minutes",
                          name: "minDeliveryScheduleMinutes",
                          type: "text-number",
                          label: _vm.$t("Tempo mínimo para entrega"),
                          instruction: _vm.$t(
                            "Tempo mínimo em minutos para iniciar a entrega de pedidos."
                          ),
                          validation: _vm.store.delivery ? "required" : "",
                        },
                        model: {
                          value: _vm.store.minDeliveryScheduleMinutes,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.store,
                              "minDeliveryScheduleMinutes",
                              $$v
                            )
                          },
                          expression: "store.minDeliveryScheduleMinutes",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "min-zero-rate-value",
                          name: "zeroRateMinValue",
                          type: "text-number",
                          currency: "R$",
                          precision: 2,
                          label: _vm.$t("Valor min. p/ taxa zero"),
                          instruction: _vm.$t(
                            "Valor mínimo de pedido para taxa zero."
                          ),
                        },
                        model: {
                          value: _vm.store.zeroRateMinValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "zeroRateMinValue", $$v)
                          },
                          expression: "store.zeroRateMinValue",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        class: _vm.store.delivery ? "required" : "",
                        attrs: {
                          id: "shipping-fee-delivery",
                          name: "shippingFee",
                          type: "text-number",
                          currency: "R$",
                          precision: 2,
                          label: _vm.$t("Valor da taxa única"),
                          instruction: _vm.$t(
                            "Valor fixo cobrado pela entrega."
                          ),
                          validation: _vm.store.delivery ? "required" : "",
                        },
                        model: {
                          value: _vm.store.shippingFees.shippingFee,
                          callback: function ($$v) {
                            _vm.$set(_vm.store.shippingFees, "shippingFee", $$v)
                          },
                          expression: "store.shippingFees.shippingFee",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        class: _vm.store.delivery ? "required" : "",
                        attrs: {
                          id: "shipping-fee-per-km-delivery",
                          name: "shippingFeePerKilometer",
                          type: "text-number",
                          currency: "R$",
                          precision: 2,
                          label: _vm.$t("Valor da taxa por KM"),
                          instruction: _vm.$t(
                            "Valor cobrado pela entrega por quilômetro rodado até o endereço de destino."
                          ),
                          validation: _vm.store.delivery ? "required" : "",
                        },
                        model: {
                          value: _vm.store.shippingFees.shippingFeePerKilometer,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.store.shippingFees,
                              "shippingFeePerKilometer",
                              $$v
                            )
                          },
                          expression:
                            "store.shippingFees.shippingFeePerKilometer",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "deliveryType",
                          name: "deliveryType",
                          type: "vue-select",
                          instructions: [
                            {
                              text: _vm.$t(
                                "Tipo de entregas que a loja realiza"
                              ),
                            },
                            {
                              text: _vm.$t(
                                "Cidade: realiza entregas na cidade inteira."
                              ),
                            },
                            {
                              text: _vm.$t(
                                "Polígono: realiza entregas dentro de uma determinada área da cidade."
                              ),
                            },
                          ],
                          label: _vm.$t("Tipo do Delivery"),
                          options: _vm.storeDeliveryTypes(),
                          placeholder: _vm.$t("Selecione"),
                        },
                        model: {
                          value: _vm.store.deliveryType,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "deliveryType", $$v)
                          },
                          expression: "store.deliveryType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "store-delivery-others-cities",
                          name: "deliveryOthersCities",
                          type: "switch",
                          instruction: _vm.$t(
                            "Permite adicionar outras cidades que a loja poderá realizar entrega."
                          ),
                          label: _vm.$t("Entrega em outras cidades?"),
                        },
                        model: {
                          value: _vm.store.deliveryOthersCities,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "deliveryOthersCities", $$v)
                          },
                          expression: "store.deliveryOthersCities",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.store.deliveryOthersCities
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "d-flex justify-content-end mb-1",
                          attrs: { md: "12" },
                        },
                        [
                          _c("e-button", {
                            attrs: {
                              variant: "primary",
                              text: _vm.$t("Adicionar Cidade"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onShowDeliveryLocationSidebar()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "12" } },
                        [
                          _c("b-table", {
                            staticClass: "bordered",
                            attrs: {
                              id: "stores-other-cities-table",
                              responsive: "",
                              striped: "",
                              "show-empty": "",
                              "empty-text": _vm.getEmptyTableAddMessage(
                                _vm.$tc("ADDRESS.CITY_NAME"),
                                "female"
                              ),
                              items: _vm.getStoreDeliveryLocations,
                              fields: _vm.deliveryLocationFields,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "cell(actions)",
                                  fn: function (row) {
                                    return [
                                      _c("e-grid-actions", {
                                        attrs: {
                                          "show-update": false,
                                          "show-delete": true,
                                        },
                                        on: {
                                          delete: function ($event) {
                                            return _vm.onRemoveDeliveryLocation(
                                              row.item
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4041282574
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-card-actions",
            {
              ref: "main-card",
              attrs: {
                "no-actions": "",
                "show-loading": _vm.loading || _vm.busy,
                title: _vm.$t("Fiscal"),
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "tax-code",
                          name: "taxCode",
                          type: "text",
                          validation: "optional|number",
                          label: _vm.$t("CNAE Fiscal"),
                        },
                        model: {
                          value: _vm.store.taxCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "taxCode", _vm._n($$v))
                          },
                          expression: "store.taxCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "tax-regime",
                          name: "taxRegime",
                          type: "vue-select",
                          validation: "required",
                          label: _vm.$t("Regime Fiscal"),
                          options: _vm.taxRegimes(),
                          placeholder: _vm.$t("Selecione"),
                        },
                        model: {
                          value: _vm.store.taxRegime,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "taxRegime", $$v)
                          },
                          expression: "store.taxRegime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "invoice-serie-number",
                          name: "invoiceSerialNumber",
                          type: "number",
                          label: _vm.$t("Série da NFe"),
                          instruction: _vm.$t(
                            "Série utilizado para emissão da NF-e"
                          ),
                          validation: "required",
                        },
                        model: {
                          value: _vm.store.invoiceSerialNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "invoiceSerialNumber", $$v)
                          },
                          expression: "store.invoiceSerialNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "invoice-last-number",
                          name: "invoiceLastNumber",
                          type: "number",
                          label: _vm.$t("Última NF-e Emitida"),
                          instruction: _vm.$t("Última NFe Emitida"),
                        },
                        model: {
                          value: _vm.store.invoiceLastNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "invoiceLastNumber", $$v)
                          },
                          expression: "store.invoiceLastNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "certificate-file",
                          name: "digitalCertificateLocal",
                          "show-as-label": _vm.digitalCertificateName,
                          type: "uploader",
                          deletable: "true",
                          "max-files": "1",
                          meta: false,
                          theme: "list",
                          accept: ".pfx",
                          "help-text": _vm.$t(
                            "Arraste e solte o certificado aqui, ou clique para selecionar"
                          ),
                          "delete-confirm-message": _vm.$t(
                            "Tem certeza que deseja excluir o Certificado Digital?"
                          ),
                          label: _vm.$t("Certificado Digital"),
                        },
                        model: {
                          value: _vm.digitalCertificateLocal,
                          callback: function ($$v) {
                            _vm.digitalCertificateLocal = $$v
                          },
                          expression: "digitalCertificateLocal",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        class: _vm.enableDigitalCertificatePassword
                          ? "required"
                          : "",
                        attrs: {
                          id: "certificate-password",
                          name: "digitalCertificatePassword",
                          disabled: !_vm.enableDigitalCertificatePassword,
                          title: _vm.enableDigitalCertificatePassword
                            ? ""
                            : _vm.$t(
                                "Insira ou altere o certificado digital para habilitar a senha"
                              ),
                          type: "password",
                          validation: _vm.enableDigitalCertificatePassword
                            ? "required"
                            : "",
                          label: _vm.$t("Senha do Certificado Digital"),
                        },
                        model: {
                          value: _vm.store.digitalCertificatePassword,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.store,
                              "digitalCertificatePassword",
                              $$v
                            )
                          },
                          expression: "store.digitalCertificatePassword",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "certificate-due-date",
                          name: "digitalCertificateDueDate",
                          type: "label",
                          filter: "date",
                          label: _vm.$t("Validade do Certificado Digital"),
                        },
                        model: {
                          value: _vm.store.digitalCertificateDueDate,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.store,
                              "digitalCertificateDueDate",
                              $$v
                            )
                          },
                          expression: "store.digitalCertificateDueDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-card-actions",
            {
              ref: "main-card",
              attrs: {
                "no-actions": "",
                "show-loading": _vm.loading || _vm.busy,
                title: _vm.$t("Loja Virtual"),
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "active-online",
                          name: "activeOnline",
                          type: "switch",
                          label: _vm.$t("Venda no Site"),
                          instruction: _vm.$t(
                            "Quando habilitado, a loja estará disponível para venda no site e no app"
                          ),
                        },
                        model: {
                          value: _vm.store.activeOnline,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "activeOnline", $$v)
                          },
                          expression: "store.activeOnline",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "max-product-items-sell",
                          name: "maxProductItemsSell",
                          type: "number",
                          label: _vm.$t("Qtd. Máxima de itens"),
                          instruction: _vm.$t(
                            "Indica quantos itens do mesmo produto a loja pode vender nos canais digitais"
                          ),
                        },
                        model: {
                          value: _vm.store.maxProductItemsSell,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "maxProductItemsSell", $$v)
                          },
                          expression: "store.maxProductItemsSell",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "max-order-value",
                          name: "maxOrderValue",
                          type: "text-number",
                          currency: "R$",
                          precision: 2,
                          label: _vm.$t("Valor máx. de venda"),
                          instruction: _vm.$t(
                            "Valor máximo permitido para uma venda"
                          ),
                        },
                        model: {
                          value: _vm.store.maxOrderValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "maxOrderValue", $$v)
                          },
                          expression: "store.maxOrderValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-card",
                {
                  staticClass: "bg-light-gray",
                  attrs: { title: _vm.$t("App B2C") },
                },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "online-payment-pix",
                              name: "onlinePaymentPix",
                              type: "switch",
                              label: _vm.$t("Pagto Pix (online)"),
                              instruction: _vm.$t(
                                "Quando habilitado, a loja aceitará pagamento via pix no site e no app"
                              ),
                            },
                            model: {
                              value: _vm.store.onlinePaymentConfig.pix,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.store.onlinePaymentConfig,
                                  "pix",
                                  $$v
                                )
                              },
                              expression: "store.onlinePaymentConfig.pix",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "online-payment-credit",
                              name: "onlinePaymentCredit",
                              type: "switch",
                              label: _vm.$t("Pagto Crédito (online)"),
                              instruction: _vm.$t(
                                "Quando habilitado, a loja aceitará pagamento via cartão de crédito no site e no app"
                              ),
                            },
                            model: {
                              value: _vm.store.onlinePaymentConfig.credit,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.store.onlinePaymentConfig,
                                  "credit",
                                  $$v
                                )
                              },
                              expression: "store.onlinePaymentConfig.credit",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "online-payment-cash",
                              name: "onlinePaymentCash",
                              type: "switch",
                              label: _vm.$t("Pagto Dinheiro (na entrega)"),
                              instruction: _vm.$t(
                                "Quando habilitado, a loja aceitará pagamentos em dinheiro na entrega de pedidos feitos no site e app"
                              ),
                            },
                            model: {
                              value: _vm.store.onlinePaymentConfig.cash,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.store.onlinePaymentConfig,
                                  "cash",
                                  $$v
                                )
                              },
                              expression: "store.onlinePaymentConfig.cash",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "online-payment-pickup",
                              name: "onlinePaymentPayOnPickup",
                              type: "switch",
                              label: _vm.$t("Pagto Cartão (na entrega)"),
                              instruction: _vm.$t(
                                "Quando habilitado, a loja aceitará pagamentos em cartão de crédito/débito na entrega de pedidos feitos no site e app"
                              ),
                            },
                            model: {
                              value: _vm.store.onlinePaymentConfig.payOnPickup,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.store.onlinePaymentConfig,
                                  "payOnPickup",
                                  $$v
                                )
                              },
                              expression:
                                "store.onlinePaymentConfig.payOnPickup",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "online-chat-enabled",
                              name: "onlineChatEnabled",
                              type: "switch",
                              label: _vm.$t("Chat ativo"),
                              instruction: _vm.$t(
                                "Quando habilitado, a loja poderá ser acionada pelos clientes via chat no app"
                              ),
                            },
                            model: {
                              value: _vm.store.onlineChatEnabled,
                              callback: function ($$v) {
                                _vm.$set(_vm.store, "onlineChatEnabled", $$v)
                              },
                              expression: "store.onlineChatEnabled",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "online-payment-pix-limit",
                              name: "onlinePaymentPixLimit",
                              type: "text-number",
                              currency: "R$",
                              precision: 2,
                              label: _vm.$t("Limite Pix"),
                              instruction: _vm.$t(
                                "Limite de valor para pagamento no PIX no site e no app"
                              ),
                            },
                            model: {
                              value: _vm.store.onlinePaymentConfig.pixLimit,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.store.onlinePaymentConfig,
                                  "pixLimit",
                                  $$v
                                )
                              },
                              expression: "store.onlinePaymentConfig.pixLimit",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "online-payment-credit-limit",
                              name: "onlinePaymentCreditLimit",
                              type: "text-number",
                              currency: "R$",
                              precision: 2,
                              label: _vm.$t("Limite Crédito"),
                              instruction: _vm.$t(
                                "Limite de valor para pagamento com cartão de crédito no site e no app"
                              ),
                            },
                            model: {
                              value: _vm.store.onlinePaymentConfig.creditLimit,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.store.onlinePaymentConfig,
                                  "creditLimit",
                                  $$v
                                )
                              },
                              expression:
                                "store.onlinePaymentConfig.creditLimit",
                            },
                          }),
                        ],
                        1
                      ),
                      false
                        ? _c(
                            "b-col",
                            { attrs: { md: "2" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "online-payment-cash-limit",
                                  name: "onlinePaymentCashLimit",
                                  type: "text-number",
                                  currency: "R$",
                                  precision: 2,
                                  label: _vm.$t("Limite Dinheiro"),
                                  instruction: _vm.$t(
                                    "Limite de valor para pagamento em dinheiro no site e no app"
                                  ),
                                },
                                model: {
                                  value:
                                    _vm.store.onlinePaymentConfig.cashLimit,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.store.onlinePaymentConfig,
                                      "cashLimit",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "store.onlinePaymentConfig.cashLimit",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-card",
                {
                  staticClass: "bg-light-gray",
                  attrs: { title: _vm.$t("App B2B") },
                },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "online-payment-pix-B2B",
                              name: "onlinePaymentPixB2B",
                              type: "switch",
                              label: _vm.$t("Pagto Pix (online)"),
                              instruction: _vm.$t(
                                "Quando habilitado, a loja aceitará pagamento via pix no site e no app"
                              ),
                            },
                            model: {
                              value: _vm.store.onlinePaymentB2BConfig.pix,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.store.onlinePaymentB2BConfig,
                                  "pix",
                                  $$v
                                )
                              },
                              expression: "store.onlinePaymentB2BConfig.pix",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "online-payment-credit-B2B",
                              name: "onlinePaymentCreditB2B",
                              type: "switch",
                              label: _vm.$t("Pagto Crédito (online)"),
                              instruction: _vm.$t(
                                "Quando habilitado, a loja aceitará pagamento via cartão de crédito no site e no app"
                              ),
                            },
                            model: {
                              value: _vm.store.onlinePaymentB2BConfig.credit,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.store.onlinePaymentB2BConfig,
                                  "credit",
                                  $$v
                                )
                              },
                              expression: "store.onlinePaymentB2BConfig.credit",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "online-payment-cash-B2B",
                              name: "onlinePaymentCashB2B",
                              type: "switch",
                              label: _vm.$t("Pagto Dinheiro (na entrega)"),
                              instruction: _vm.$t(
                                "Quando habilitado, a loja aceitará pagamentos em dinheiro na entrega de pedidos feitos no site e app"
                              ),
                            },
                            model: {
                              value: _vm.store.onlinePaymentB2BConfig.cash,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.store.onlinePaymentB2BConfig,
                                  "cash",
                                  $$v
                                )
                              },
                              expression: "store.onlinePaymentB2BConfig.cash",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "online-payment-pickup-B2B",
                              name: "onlinePaymentPayOnPickupB2B",
                              type: "switch",
                              label: _vm.$t("Pagto Cartão (na entrega)"),
                              instruction: _vm.$t(
                                "Quando habilitado, a loja aceitará pagamentos em cartão de crédito/débito na entrega de pedidos feitos no site e app"
                              ),
                            },
                            model: {
                              value:
                                _vm.store.onlinePaymentB2BConfig.payOnPickup,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.store.onlinePaymentB2BConfig,
                                  "payOnPickup",
                                  $$v
                                )
                              },
                              expression:
                                "store.onlinePaymentB2BConfig.payOnPickup",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "online-payment-pix-limit-B2B",
                              name: "onlinePaymentPixLimitB2B",
                              type: "text-number",
                              currency: "R$",
                              precision: 2,
                              label: _vm.$t("Limite Pix"),
                              instruction: _vm.$t(
                                "Limite de valor para pagamento no PIX no site e no app"
                              ),
                            },
                            model: {
                              value: _vm.store.onlinePaymentB2BConfig.pixLimit,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.store.onlinePaymentB2BConfig,
                                  "pixLimit",
                                  $$v
                                )
                              },
                              expression:
                                "store.onlinePaymentB2BConfig.pixLimit",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "online-payment-credit-limit-B2B",
                              name: "onlinePaymentCreditLimitB2B",
                              type: "text-number",
                              currency: "R$",
                              precision: 2,
                              label: _vm.$t("Limite Crédito"),
                              instruction: _vm.$t(
                                "Limite de valor para pagamento com cartão de crédito no site e no app"
                              ),
                            },
                            model: {
                              value:
                                _vm.store.onlinePaymentB2BConfig.creditLimit,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.store.onlinePaymentB2BConfig,
                                  "creditLimit",
                                  $$v
                                )
                              },
                              expression:
                                "store.onlinePaymentB2BConfig.creditLimit",
                            },
                          }),
                        ],
                        1
                      ),
                      false
                        ? _c(
                            "b-col",
                            { attrs: { md: "2" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "online-payment-cash-limit-B2B",
                                  name: "onlinePaymentCashLimitB2B",
                                  type: "text-number",
                                  currency: "R$",
                                  precision: 2,
                                  label: _vm.$t("Limite Dinheiro"),
                                  instruction: _vm.$t(
                                    "Limite de valor para pagamento em dinheiro no site e no app"
                                  ),
                                },
                                model: {
                                  value:
                                    _vm.store.onlinePaymentB2BConfig.cashLimit,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.store.onlinePaymentB2BConfig,
                                      "cashLimit",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "store.onlinePaymentB2BConfig.cashLimit",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          name: "imageWideLocal",
                          type: "uploader",
                          deletable: "true",
                          "max-files": "1",
                          meta: false,
                          label: _vm.$t("Imagem Wide"),
                        },
                        model: {
                          value: _vm.imageWideLocal,
                          callback: function ($$v) {
                            _vm.imageWideLocal = $$v
                          },
                          expression: "imageWideLocal",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          name: "imageSquareLocal",
                          type: "uploader",
                          deletable: "true",
                          "max-files": "1",
                          meta: false,
                          label: _vm.$t("Imagem Quadrada"),
                        },
                        model: {
                          value: _vm.imageSquareLocal,
                          callback: function ($$v) {
                            _vm.imageSquareLocal = $$v
                          },
                          expression: "imageSquareLocal",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.store.type === "Bar"
            ? _c(
                "b-card-actions",
                {
                  attrs: {
                    "no-actions": "",
                    "show-loading": _vm.loading || _vm.busy,
                    title: _vm.$t("Bar"),
                  },
                },
                [
                  _c(
                    "b-row",
                    { attrs: { md: "5" } },
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "12" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "waiter-comission",
                              name: "waiterComission",
                              type: "switch",
                              label: _vm.$t("Habilitar gorjeta do garçom"),
                              instruction: _vm.$t(
                                "Quando habilitado, a loja do tipo Bar irá habilitar opção da gorjeta pro garçom"
                              ),
                            },
                            model: {
                              value: _vm.store.waiterComission,
                              callback: function ($$v) {
                                _vm.$set(_vm.store, "waiterComission", $$v)
                              },
                              expression: "store.waiterComission",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.store.waiterComission,
                          expression: "store.waiterComission",
                        },
                      ],
                    },
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "5" } },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "d-flex justify-content-end mb-1",
                              attrs: { md: "12" },
                            },
                            [
                              _c("e-button", {
                                attrs: {
                                  variant: "primary",
                                  text: _vm.$t("Adicionar gorjeta"),
                                },
                                on: { click: _vm.onAddWaiterComission },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("b-table", {
                                staticClass: "bordered",
                                attrs: {
                                  id: "stores-waiter-comission-table",
                                  responsive: "",
                                  striped: "",
                                  "show-empty": "",
                                  items: _vm.getStoreWaiterComissions,
                                  fields: _vm.waiterComissionFields,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "cell(actions)",
                                      fn: function (row) {
                                        return [
                                          _c("e-grid-actions", {
                                            attrs: {
                                              "show-update": false,
                                              "show-delete": true,
                                            },
                                            on: {
                                              delete: function ($event) {
                                                return _vm.onRemoveWaiterComission(
                                                  row.item
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(comissionFee)",
                                      fn: function (row) {
                                        return [
                                          _c("FormulateInput", {
                                            attrs: {
                                              id:
                                                "waiter_comission_fee-" +
                                                row.index,
                                              type: "text-percetage",
                                              validation: "number|min:0.01",
                                              name: "comissionFee",
                                              currency: "%",
                                              precision: 2,
                                              label: "",
                                              "currency-symbol-position":
                                                "suffix",
                                            },
                                            model: {
                                              value: row.item.comissionFee,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  row.item,
                                                  "comissionFee",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "row.item.comissionFee",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2805503822
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-card-actions",
            {
              attrs: {
                "no-actions": "",
                "show-loading": _vm.loading || _vm.busy,
                title: _vm.$t("Parametrizações"),
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "max_cash_amount_pay_box",
                          name: "maxCashAmountPayBox",
                          label: _vm.$t("Valor máx. permitido no PDV"),
                          type: "text-number",
                          currency: "R$",
                          precision: 2,
                          instructions: [
                            {
                              text: _vm.$t(
                                "Valor máximo em dinheiro permitido na gaveta dos PDVs desta loja, caso o valor seja excedido a tela do caixa será bloqueado e pedirá para realizar uma sangria."
                              ),
                            },
                            {
                              text: _vm.$t(
                                "Caso não seja informado, o valor de R$ 900 será aplicado como limite padrão."
                              ),
                            },
                          ],
                        },
                        model: {
                          value: _vm.store.maxCashAmountPayBox,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "maxCashAmountPayBox", $$v)
                          },
                          expression: "store.maxCashAmountPayBox",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "max_accounts_payable_value_on_pay_box",
                          name: "maxAccountsPayableValueOnPayBox",
                          label: _vm.$t(
                            "Valor máx. permitido no mês para despesa no PDV"
                          ),
                          type: "text-number",
                          currency: "R$",
                          precision: 2,
                          instructions: [
                            {
                              text: _vm.$t(
                                "Valor máximo permitido do PDV para criar despesa no mês."
                              ),
                            },
                            {
                              text: _vm.$t(
                                "Caso não seja informado, não haverá limite para criação de despesas no caixa."
                              ),
                            },
                          ],
                        },
                        model: {
                          value: _vm.store.maxAccountsPayableValueOnPayBox,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.store,
                              "maxAccountsPayableValueOnPayBox",
                              $$v
                            )
                          },
                          expression: "store.maxAccountsPayableValueOnPayBox",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c("FormulateInput", {
                        attrs: {
                          instruction: _vm.$t(
                            "Quando habilitado, impedirá a abertura do caixa se o valor digitado for maior que o saldo disponível na conta troco."
                          ),
                          type: "switch",
                          label: _vm.$t("Trava na abertura de caixa"),
                        },
                        model: {
                          value: _vm.store.lockPdvOpening,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "lockPdvOpening", $$v)
                          },
                          expression: "store.lockPdvOpening",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c("e-address", {
                    ref: "address",
                    attrs: {
                      "show-map": true,
                      "show-lat-lng": true,
                      "show-loading": _vm.loading || _vm.busy,
                    },
                    model: {
                      value: _vm.store.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.store, "address", $$v)
                      },
                      expression: "store.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c(
                    "b-card-actions",
                    {
                      ref: "main-card",
                      attrs: {
                        "no-actions": "",
                        "show-loading": _vm.loading || _vm.busy,
                        title: _vm.$t("Horários de Funcionamento"),
                      },
                    },
                    [_c("opening-hours")],
                    1
                  ),
                  _c(
                    "b-card-actions",
                    {
                      ref: "main-card",
                      attrs: {
                        "no-actions": "",
                        "show-loading": _vm.loading || _vm.busy,
                        title: _vm.$t("Telefones"),
                      },
                    },
                    [
                      _c("FormulateInput", {
                        ref: "telephoneGroup",
                        attrs: {
                          name: "telephones",
                          type: "group",
                          "error-behavior": "live",
                          repeatable: true,
                          "add-label": _vm.$t("Adicionar Telefone"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var index = ref.index
                              return [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { md: "5" } },
                                      [
                                        _c("FormulateInput", {
                                          staticClass: "required",
                                          attrs: {
                                            id: "store-telephone-type-" + index,
                                            type: "vue-select",
                                            name: "type",
                                            label: _vm.$t("Tipo"),
                                            options: _vm.telephoneTypes(),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { md: "7" } },
                                      [
                                        _c("FormulateInput", {
                                          directives: [
                                            {
                                              name: "mask",
                                              rawName: "v-mask",
                                              value: [
                                                "(##) ####-####",
                                                "(##) #####-####",
                                              ],
                                              expression:
                                                "['(##) ####-####', '(##) #####-####']",
                                            },
                                          ],
                                          staticClass: "required",
                                          attrs: {
                                            id:
                                              "store-telephone-number-" + index,
                                            type: "text",
                                            name: "number",
                                            validation: "optional|min:14",
                                            maxlength: "15",
                                            instruction: _vm.$t(
                                              "Exemplo: (99) 99999-9999"
                                            ),
                                            label: _vm.$t("Telefone"),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.store.telephones,
                          callback: function ($$v) {
                            _vm.$set(_vm.store, "telephones", $$v)
                          },
                          expression: "store.telephones",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("FAB", {
            attrs: {
              "main-tooltip": _vm.$t("Ações"),
              "main-icon": "keyboard_command_key",
              actions: _vm.actions,
              "fixed-tooltip": true,
              busy: _vm.busy,
            },
            on: {
              save: _vm.save,
              cancel: _vm.cancel,
              active: _vm.activateDeactivateStore,
              inactive: _vm.activateDeactivateStore,
              export: _vm.exportStore,
              import: _vm.importStore,
            },
          }),
        ],
        1
      ),
      _c("delivery-location-sidebar", {
        ref: "deliveryLocationSidebar",
        on: { "add-item": _vm.onAddDeliveryLocation },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }